'use client'

import { IconOpenAI, IconUser } from '@/components/ui/icons'
import { cn } from '@/lib/utils'
import { spinner } from './spinner'
import { MemoizedReactMarkdown } from '../markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
// import { StreamableValue, useStreamableValue } from 'ai/rsc'
import { useStreamableText } from '@/lib/hooks/use-streamable-text'
import { useRef } from 'react'
import { ChatMessage } from '@/lib/types'

// Different types of message bubbles.

export function LanguageSpeakMessage({ msg }: { msg: ChatMessage }) {
	const audioRef = useRef<HTMLAudioElement | null>(null);
	const playAudio = () => {
		if(audioRef.current) {
			audioRef.current.src = msg.replyAudio;
			audioRef.current.play();
		}
	}
	return (
		<div className="space-y-2">
			<audio ref={audioRef} controls style={{ display: 'none' }} />
			<div className="group relative flex items-start md:-ml-12">
				<div className="flex size-[25px] shrink-0 select-none items-center justify-center rounded-md border bg-background shadow-sm">
					<IconUser />
				</div>
				<div className="ml-4 flex-1 space-y-2 overflow-hidden px-1">
					<div>{msg.transcription}</div>
					{msg.transcriptionTransl != '' && <div className="text-gray-500">{msg.transcriptionTransl}</div>}
				</div>
			</div>
			<div className='group relative flex items-start md:-ml-12 cursor-pointer' onMouseDown={playAudio} title="Click to play the audio again">
				<div className="flex size-[24px] shrink-0 select-none items-center justify-center rounded-md border bg-primary text-primary-foreground shadow-sm">
					<IconOpenAI />
				</div>
				<div className="ml-4 flex-1 space-y-2 overflow-hidden px-1">
					<div>{msg.reply}</div>
					<div className="text-gray-500">{msg.replyTranslation}</div>
				</div>
			</div>
		</div>
	)
}

export function UserMessage({ children }: { children: React.ReactNode }) {
	return (
		<div className="group relative flex items-start md:-ml-12">
			<div className="flex size-[25px] shrink-0 select-none items-center justify-center rounded-md border bg-background shadow-sm">
				<IconUser />
			</div>
			<div className="ml-4 flex-1 space-y-2 overflow-hidden pl-2">
				{children}
			</div>
		</div>
	)
}

export function BotMessage({
	// content,
	className
}: {
	// content: string | StreamableValue<string>
	className?: string
}) {
	const text = '' //useStreamableText(content)

	return (
		<div className={cn('group relative flex items-start md:-ml-12', className)}>
			<div className="flex size-[24px] shrink-0 select-none items-center justify-center rounded-md border bg-primary text-primary-foreground shadow-sm">
				<IconOpenAI />
			</div>
			<div className="ml-4 flex-1 space-y-2 overflow-hidden px-1">
				<MemoizedReactMarkdown
					className="prose break-words dark:prose-invert prose-p:leading-relaxed prose-pre:p-0"
					remarkPlugins={[remarkGfm, remarkMath]}
					components={{
						p({ children }) {
							return <p className="mb-2 last:mb-0">{children}</p>
						},
						// code({ node, inline, className, children, ...props }) {
						// 	if (children.length) {
						// 		if (children[0] == '▍') {
						// 			return (
						// 				<span className="mt-1 animate-pulse cursor-default">▍</span>
						// 			)
						// 		}

						// 		children[0] = (children[0] as string).replace('`▍`', '▍')
						// 	}

						// 	const match = /language-(\w+)/.exec(className || '')

						// 	if (inline) {
						// 		return (
						// 			<code className={className} {...props}>
						// 				{children}
						// 			</code>
						// 		)
						// 	}

						// 	return (
						// 		<CodeBlock
						// 			key={Math.random()}
						// 			language={(match && match[1]) || ''}
						// 			value={String(children).replace(/\n$/, '')}
						// 			{...props}
						// 		/>
						// 	)
						// }
					}}
				>
					{text}
				</MemoizedReactMarkdown>
			</div>
		</div>
	)
}

export function BotCard({
	children,
	showAvatar = true
}: {
	children: React.ReactNode
	showAvatar?: boolean
}) {
	return (
		<div className="group relative flex items-start md:-ml-12">
			<div
				className={cn(
					'flex size-[24px] shrink-0 select-none items-center justify-center rounded-md border bg-primary text-primary-foreground shadow-sm',
					!showAvatar && 'invisible'
				)}
			>
				<IconOpenAI />
			</div>
			<div className="ml-4 flex-1 pl-2">{children}</div>
		</div>
	)
}

export function SystemMessage({ children }: { children: React.ReactNode }) {
	return (
		<div
			className={
				'mt-2 flex items-center justify-center gap-2 text-xs text-gray-500'
			}
		>
			<div className={'max-w-[600px] flex-initial p-2'}>{children}</div>
		</div>
	)
}

export function SpinnerMessage() {
	return (
		<div className="group relative flex items-start md:-ml-12">
			<div className="flex size-[24px] shrink-0 select-none items-center justify-center rounded-md border bg-primary text-primary-foreground shadow-sm">
				<IconOpenAI />
			</div>
			<div className="ml-4 h-[24px] flex flex-row items-center flex-1 space-y-2 overflow-hidden px-1">
				{spinner}
			</div>
		</div>
	)
}
