'use client'; // Contains a form, so needs to be a client component

import { Chat } from "@/components/chat";
import { ChatContextProvider } from "@/lib/chat/chat-state";

export default function Home() {
	const missingKeys = [] as any[];

	return (
		<Chat id="123" session={undefined} missingKeys={missingKeys} />
	);
}
