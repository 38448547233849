
import { ExternalLink } from '@/components/external-link'

export function EmptyScreen() {
	return (
		<div className="mx-auto max-w-2xl px-4">
			<div className="flex flex-col gap-2 rounded-lg border bg-background p-8">
				<h1 className="text-lg font-semibold">
					Welcome to the Language Learner AI!
				</h1>
				<p className="leading-normal text-muted-foreground">
					This is an AI assistant which will help you learn Spanish. It&apos;s
					built on technology from {' '}
					<ExternalLink href="https://openai.com">OpenAI</ExternalLink>, so
					you need to know that you can&apos;t rely on information provided
					by the assistant, but instead have to verify it&apos;s claims yourself.
				</p>
				<p className="leading-normal text-muted-foreground">
					For now the system is in limited demo mode, you can request
					an account by writing an email to {' '}
					<ExternalLink href="https://acoveo.com">Acoveo</ExternalLink>.
				</p>
				<p className="leading-normal text-muted-foreground">
					You can speak to the assistant in either English or Spanish
					and it will reply to you in Spanish, providing translations
					to English, for both what you said and it&apos;s reply.
				</p>
			</div>
		</div>
	)
}
