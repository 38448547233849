'use client'

import * as React from 'react'
import Textarea from 'react-textarea-autosize'

// import { useActions, useUIState } from 'ai/rsc'

import { LanguageSpeakMessage, UserMessage } from './stocks/message'
// import { type AI } from '@/lib/chat/actions'
import { Button } from '@/components/ui/button'
import { IconArrowElbow, IconPlus, IconSpeakerModerate } from '@/components/ui/icons'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger
} from '@/components/ui/tooltip'
import { useEnterSubmit } from '@/lib/hooks/use-enter-submit'
import { useRouter } from 'next/navigation'
import { useRef, useState } from 'react'
import { ChatContext, useChatContext, useChatDispatchContext } from '@/lib/chat/chat-state'
import { getMessages } from '@/app/actions'
import { ChatMessage } from '@/lib/types'

export function PromptForm({
	input,
	setInput
}: {
	input: string
	setInput: (value: string) => void
}) {
	const router = useRouter()
	const { formRef, onKeyDown } = useEnterSubmit()
	const inputRef = React.useRef<HTMLTextAreaElement>(null)
	// const { submitUserMessage } = useActions()
	const chatDispatchContext = useChatDispatchContext();
  	const chatState = useChatContext();
	const STATE_TEXT_NOT_RECORDING = 'Click / tap to record';
	const STATE_TEXT_RECORDING = 'Recording... Click / tap to stop';
	const STATE_TEXT_THINKING = 'Thinking ... please wait';

	const [stateText, setStateText] = useState(STATE_TEXT_NOT_RECORDING);
	const [isRecording, setIsRecording] = useState(false);
	const [isThinking, setIsThinking] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const mediaRecorderRef = useRef<MediaRecorder | null>(null);
	const audioChunksRef = useRef<Blob[]>([]);
	const audioRef = useRef<HTMLAudioElement | null>(null);
	
	const toggleRecording = async () => {
		if(isRecording)
			return stopRecording();
		else
			return startRecording();
	};
	const startRecording = async () => {
		console.log('Starting recording');
		setError(null);
		audioChunksRef.current = [];
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			const mediaRecorder = new MediaRecorder(stream);
		
			mediaRecorder.ondataavailable = (event) => {
				if (event.data.size > 0) {
					audioChunksRef.current.push(event.data);
				}
			};
		
			mediaRecorder.onstop = async () => {
				stream.getAudioTracks().forEach(t => t.stop()); // Only this de-activates the microphone active state of the browser and let's go of the microphone from the OS
				setIsThinking(true);
				setStateText(STATE_TEXT_THINKING);
				const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
				const formData = new FormData();
				formData.append('file', audioBlob, 'recording.webm');
				const history = [] as any[];
				const messages = getMessages(chatState);
				messages.forEach(msg => {
					if(msg.transcription !== '')
						history.push({role: 'user', content: msg.transcription});
					if(msg.reply !== '')
						history.push({role: 'assistant', content: msg.reply});
				});
				formData.append('msgHistory', JSON.stringify(history));
		
				try {
					const response = await fetch('/api/speak', {
						method: 'POST',
						body: formData,
					});
					setIsThinking(false);
					setStateText(STATE_TEXT_NOT_RECORDING);
					if (!response.ok)
						throw new Error('Failed to transcribe audio');
					const respWait = await response;
					console.log(respWait);
					const formDataResp = await response.json();
					const data = formDataResp.audio;
					const replyAudio = 'data:audio/mpeg;base64,' + data;
					if(audioRef.current) {
						audioRef.current.src = replyAudio;
						audioRef.current.play();
					}
					const chatMsg:ChatMessage = {
						id: '',
						createdAt: new Date(),
						transcription: formDataResp.transcribedText || '',
						transcriptionTransl: formDataResp.transcribedTranslatedText || '',
						reply: formDataResp.text || '',
						replyTranslation: formDataResp.translatedText || '',
						replyAudio: replyAudio,
						ui: null
					}
					const chatMsgCopy = JSON.parse(JSON.stringify(chatMsg));
					chatMsg.ui = <LanguageSpeakMessage msg={chatMsgCopy}></LanguageSpeakMessage> // Need to pass a copy of the msg to avoid infinite recursion
					chatDispatchContext({type: 'addMsg', message: chatMsg});
				} catch (error) {
					setError('Error transcribing audio: ' + (error && (error as any).message) ? (error as any).message : error);
					setIsThinking(false);
					setStateText(STATE_TEXT_NOT_RECORDING);
				}
			};
		
			mediaRecorder.start();
			mediaRecorderRef.current = mediaRecorder;
			setIsRecording(true); // stop is handled in stopRecording()
			setStateText(STATE_TEXT_RECORDING);
		} catch (error) {
			setError('Error accessing microphone');
		}
	};
	
	const stopRecording = () => {
		console.log('Stopping recording');
		setStateText(STATE_TEXT_NOT_RECORDING);
		if (mediaRecorderRef.current) {
			mediaRecorderRef.current.stop();
			setIsRecording(false);
		}
	};

	React.useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus()
		}
	}, [])

	return (
		<form
			ref={formRef}
			onSubmit={async (e: any) => {
				e.preventDefault()

				// Blur focus on mobile
				if (window.innerWidth < 600) {
					e.target['message']?.blur()
				}

				const value = input.trim()
				setInput('')
				if (!value) return

				// Optimistically add user message UI
				// setMessages(currentMessages => [
				// 	...currentMessages,
				// 	{
				// 		id: nanoid(),
				// 		display: <UserMessage>{value}</UserMessage>
				// 	}
				// ])

				// Submit and get response message
				// const responseMessage = await submitUserMessage(value)
				// setMessages(currentMessages => [...currentMessages, responseMessage])
			}}
		>
			<div className="relative flex max-h-60 w-full grow flex-col overflow-hidden bg-background px-8 sm:rounded-md sm:border sm:px-12">
				<Tooltip>
					<TooltipTrigger asChild>
						<Button
							variant="outline"
							size="icon"
							className="absolute left-0 top-[14px] size-8 rounded-full bg-background p-0 sm:left-4"
							onClick={() => {
								router.push('/new')
							}}
						>
							<IconPlus />
							<span className="sr-only">New Chat</span>
						</Button>
					</TooltipTrigger>
					<TooltipContent>New Chat</TooltipContent>
				</Tooltip>
				<Textarea
					ref={inputRef}
					tabIndex={0}
					onKeyDown={onKeyDown}
					disabled
					placeholder={ stateText }
					className="min-h-[60px] w-full resize-none bg-transparent px-4 py-[1.3rem] text-right focus-within:outline-none sm:text-sm"
					autoFocus
					spellCheck={false}
					autoComplete="off"
					autoCorrect="off"
					name="message"
					rows={1}
					value={input}
					onChange={e => setInput(e.target.value)}
				/>
				<div className="absolute right-0 top-[13px] sm:right-4">
					<audio ref={audioRef} controls style={{ display: 'none' }} />
					<Tooltip>
						<TooltipTrigger asChild>
							<Button className={isRecording ? 'bg-primary/60 hover:bg-primary/60' : ''}
									disabled={isThinking}
									size="icon"
									onMouseDown={toggleRecording}>
								<IconSpeakerModerate />
								<span className="sr-only">{ stateText }</span>
							</Button>
						</TooltipTrigger>
						<TooltipContent>Start recording</TooltipContent>
					</Tooltip>
				</div>
			</div>
		</form>
	)
}
